export enum ETransactionStatus {
    NO_ACTION = "NO_ACTION",
    WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
    IN_PROGRESS = "IN_PROGRESS",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    TRAITS_SUCCESS = "TRAITS_SUCCESS",
}

export enum EActionTypes {
    DATA = "DATA",
    ERROR = "ERROR",
    CONFIRMATION = "CONFIRMATION",
    CALLING_CONTRACT = "CALLING_CONTRACT",
    TRANSACTION_HASH = "TRANSACTION_HASH",
    RESET = "RESET",
}

export enum EContractTransactionStatus {
    CONFIRMATION = "confirmation",
    DATA = "data",
    TRANSACTION_HASH = "transactionHash",
    ERROR = "error",
    RECEIPT = "receipt",
}

export enum EProjectTransactionTypes {
    FREEZE = "FREEZE",
    RE_MINT = "RE_MINT",
}

export enum ETransactionErrorName {
    UNKNOWN_ERROR = "Unknown Error",
    CANCELED_BY_USER = "Transaction Canceled by user",
    CLAIM_TRAIT_EVENT = "Claim Trait Event error",
    CLAIM_TRAIT = "Claim Trait error",
    MINT_AVATAR_EVENT = "Mint Avatar Event error",
    MINT_AVATAR = "Mint Avatar error",
    ASSEMBLE_AVATAR_EVENT = "Assemble Avatar Event error",
    ASSEMBLE_AVATAR = "Assemble Avatar error",
    DETACH_TRAIT_EVENT = "Detach Trait Event error",
    DETACH_TRAIT = "Detach Trait error",
    CREATE_TRAITS_EVENT = "Create Traits Event error",
    CREATE_TRAITS = "Create Traits error",
    REGISTER_QK_TRANSFER_EVENT = "Register QK Transer Event error",
    REGISTER_CLAIMED_TRAIT_EVENT = "Register Claimed Trait Event error",
    FREEZE_AVATAR_EVENT = "Freeze Avatar Event error",
    FREEZE_AVATAR = "Freeze Avatar error",
}
