import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import Web3 from "web3";
import axios from "axios";
import usePrevious from "../usePrevious";
import { getAvatarReducer, TError } from "@reducers/avatarReducer";
import { CLAIM_PRICE, MINT_TRAITS, SIGNATURE } from "@constants/apiEndpoints";
import { FREEZE_CONTRACT_ADDR, CLOUDFLARE_API_BASE_URL } from "@constants/env";
import { EActionTypes, ETransactionStatus, EContractTransactionStatus, ETransactionErrorName } from "@constants/enums";
import useContractsInstancer from "@hooks/useContractsInstancer";
import contracts from "@constants/avatarContracts";

type TUseAvatarContract = {
    tokenIdList: string[] | null;
    traitAddresses: string[] | null;
    txId: string | null;
    status: ETransactionStatus;
    error: TError | null;
    createTraits: (tokenId: number) => void;
    hasMintedTraits: (tokenId: number) => Promise<boolean>;
    assembleAvatar: (tokenId: number, traitsList: number[]) => void;
    detachTrait: (tokenId: number, traitsList: number[]) => void;
    getExternalTokenId: (externalId: number) => Promise<number>;
    getClaimPrice: (traitsQuantity: number) => any;
    claimTrait: (traitsQuantity: number) => void;
    freezeAvatar: (tokenId: number) => void;
};

const useAvatarContract = (web3: Web3 | undefined, walletAddress: string): TUseAvatarContract => {
    const [claimTraitContract, qkContract, freezeContract] =
        useContractsInstancer(web3, contracts);
    const [state, dispatch] = getAvatarReducer();
    const { error, tokenIdList, traitAddresses, txId, status } = state;
    const prevStatus = usePrevious(status);

    useEffect(() => {
        if (prevStatus === ETransactionStatus.IN_PROGRESS && status === ETransactionStatus.ERROR) {
            dispatchError({
                name: ETransactionErrorName.CANCELED_BY_USER,
                message: ETransactionErrorName.CANCELED_BY_USER,
                code: 4001,
            } as TError);
        }
    }, [status]);

    const claimTrait = async (traitsQuantity) => {
        prepareToCall();

        try {
            registerClaimedTraitEvent();

            const whitelistedData = (
                await axios.get(`${CLOUDFLARE_API_BASE_URL}${SIGNATURE}`.replace(":address", walletAddress), { params: { numberOfTraits: traitsQuantity }})
            ).data;

            const { signature, from, to, traitAddresses, traitIds, price } = whitelistedData;

            await claimTraitContract.methods
                .mint(walletAddress, from, to, traitAddresses, traitIds, price, signature)
                .send({ from: walletAddress, value: price })
                .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) =>
                    dispatch({
                        type: EActionTypes.TRANSACTION_HASH,
                        payload: {
                            tokenIdList: null,
                            traitAddresses: null,
                            error: null,
                            txId: txId,
                            status: ETransactionStatus.IN_PROGRESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                    dispatchError({ name: ETransactionErrorName.CLAIM_TRAIT_EVENT, message, code } as TError)
                );
        } catch ({ code, message }: any) {
            dispatchError({ name: ETransactionErrorName.CLAIM_TRAIT, message, code } as TError);
        }
    };

    const getClaimPrice = async (traitsQuantity: number) => {

        try {
            const priceData = (
                await axios.get(`${CLOUDFLARE_API_BASE_URL}${CLAIM_PRICE}`.replace(":address", walletAddress), { params: { numberOfTraits: traitsQuantity }})
            ).data;

            return priceData.price;
        } catch ({ code, message }: any) {
            dispatchError({ name: ETransactionErrorName.CLAIM_TRAIT, message, code } as TError);
        }
    };

    const assembleAvatar = async (tokenId: number, traitsList: number[]) => {
        prepareToCall();

        try {
            await qkContract.methods
                .setTraitsToAvatar(tokenId, traitsList)
                .send({ from: walletAddress })
                .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) =>
                    dispatch({
                        type: EActionTypes.TRANSACTION_HASH,
                        payload: {
                            tokenIdList: null,
                            traitAddresses: null,
                            error: null,
                            txId: txId,
                            status: ETransactionStatus.IN_PROGRESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.RECEIPT, () =>
                    dispatch({
                        type: EActionTypes.DATA,
                        payload: {
                            tokenIdList: null,
                            traitAddresses: null,
                            error: null,
                            txId,
                            status: ETransactionStatus.SUCCESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                    dispatchError({ name: ETransactionErrorName.ASSEMBLE_AVATAR_EVENT, message, code } as TError)
                );
        } catch ({ code, message }: any) {
            dispatchError({ name: ETransactionErrorName.ASSEMBLE_AVATAR, message, code } as TError);
        }
    };

    const detachTrait = async (tokenId: number, traitsList: number[]) => {
        prepareToCall();

        try {
            await qkContract.methods
                .setTraitsToAvatar(tokenId, traitsList)
                .send({ from: walletAddress })
                .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) =>
                    dispatch({
                        type: EActionTypes.TRANSACTION_HASH,
                        payload: {
                            tokenIdList: null,
                            traitAddresses: null,
                            error: null,
                            txId: txId,
                            status: ETransactionStatus.IN_PROGRESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.RECEIPT, () =>
                    dispatch({
                        type: EActionTypes.DATA,
                        payload: {
                            tokenIdList,
                            traitAddresses: null,
                            error: null,
                            txId,
                            status: ETransactionStatus.SUCCESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                    dispatchError({ name: ETransactionErrorName.DETACH_TRAIT_EVENT, message, code } as TError)
                );
        } catch ({ code, message }: any) {
            dispatchError({ name: ETransactionErrorName.DETACH_TRAIT, message, code } as TError);
        }
    };

    const hasMintedTraits = async (tokenId: number): Promise<boolean> => {
        try {
            return await qkContract.methods.hasMintedTraits(tokenId).call();
        } catch (e) {
            return false;
        }
    };

    const getExternalTokenId = async (externalId: number): Promise<number> =>
        await qkContract.methods.internalToExternalMapping(externalId).call();

    const createTraits = async (avatarTokenId: number) => {
        prepareToCall();

        try {
            const traitsData = (
                await axios.get(generatePath(`${CLOUDFLARE_API_BASE_URL}${MINT_TRAITS}`, { tokenid: avatarTokenId }))
            ).data;

            const { tokenId, signature, traits } = traitsData;

            await qkContract.methods
                .mintTraits(tokenId, traits, signature)
                .send({ from: walletAddress })
                .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) =>
                    dispatch({
                        type: EActionTypes.TRANSACTION_HASH,
                        payload: {
                            tokenIdList: null,
                            traitAddresses: null,
                            error: null,
                            txId: txId,
                            status: ETransactionStatus.IN_PROGRESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.RECEIPT, () =>
                    dispatch({
                        type: EActionTypes.DATA,
                        payload: {
                            tokenIdList: traits,
                            traitAddresses: null,
                            error: null,
                            txId,
                            status: ETransactionStatus.TRAITS_SUCCESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                    dispatchError({ name: ETransactionErrorName.CREATE_TRAITS_EVENT, message, code } as TError)
                );
        } catch ({ code, message }: any) {
            dispatchError({ name: ETransactionErrorName.CREATE_TRAITS, message } as TError);
        }
    };

    const freezeAvatar = async (tokenId: number) => {
        prepareToCall();
        let isApproved = false;

        try {
            console.log(tokenId)
            isApproved = await qkContract.methods.isApprovedForAll(walletAddress, FREEZE_CONTRACT_ADDR).call();

            if (!isApproved) {
                await qkContract.methods
                    .setApprovalForAll(FREEZE_CONTRACT_ADDR, true)
                    .send({ from: walletAddress })
                    .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) =>
                        dispatch({
                            type: EActionTypes.TRANSACTION_HASH,
                            payload: {
                                tokenIdList: null,
                                traitAddresses: null,
                                error: null,
                                txId: txId,
                                status: ETransactionStatus.IN_PROGRESS,
                            },
                        })
                    )
                    .once(EContractTransactionStatus.RECEIPT, async () => {
                        isApproved = true;
                    })
                    .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) => {
                        console.log({ code, message });
                        dispatchError({ name: ETransactionErrorName.FREEZE_AVATAR, message, code } as TError)
                    });
            }

            prepareToCall();
            registerFreezeAvatarEvent();

            await freezeContract.methods
                .freeze(tokenId)
                .send({ from: walletAddress })
                .once(EContractTransactionStatus.TRANSACTION_HASH, (txId: string) =>
                    dispatch({
                        type: EActionTypes.TRANSACTION_HASH,
                        payload: {
                            tokenIdList: null,
                            traitAddresses: null,
                            error: null,
                            txId: txId,
                            status: ETransactionStatus.IN_PROGRESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.RECEIPT, () =>
                    dispatch({
                        type: EActionTypes.DATA,
                        payload: {
                            tokenIdList,
                            traitAddresses: null,
                            error: null,
                            txId,
                            status: ETransactionStatus.SUCCESS,
                        },
                    })
                )
                .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) => {
                    console.log({ code, message });
                    dispatchError({ name: ETransactionErrorName.DETACH_TRAIT_EVENT, message, code } as TError)
                });
        } catch ({ code, message }: any) {
            console.log({ code, message });
            dispatchError({ name: ETransactionErrorName.FREEZE_AVATAR, message, code } as TError);
        }
    };

    const registerQKTransferEvent = (quantity: number) => {
        let quantityTokens = 0;
        const tokenIdList: string[] = [];

        qkContract.events
            .Transfer({}, (error: unknown, _) => {
                if (error) {
                    console.error(error);
                    const message = error instanceof Error ? error.message : ETransactionErrorName.UNKNOWN_ERROR;
                    dispatchError({ name: ETransactionErrorName.REGISTER_QK_TRANSFER_EVENT, message, code: -1 });
                    return;
                }
            })
            .on(EContractTransactionStatus.DATA, ({ returnValues: { tokenId, to } }) => {
                if (to.toLowerCase() !== walletAddress.toLowerCase()) {
                    return;
                }

                quantityTokens++;
                tokenIdList.push(tokenId);

                if (quantityTokens === quantity) {
                    dispatch({
                        type: EActionTypes.DATA,
                        payload: {
                            tokenIdList,
                            traitAddresses: null,
                            error: null,
                            txId,
                            status: ETransactionStatus.SUCCESS,
                        },
                    });
                }
            })
            .on(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                dispatchError({ name: ETransactionErrorName.REGISTER_QK_TRANSFER_EVENT, message, code } as TError)
            );
    };

    const registerClaimedTraitEvent = () => {
        claimTraitContract.events
            .TraitsClaimed({}, (error: unknown, _) => {
                if (error) {
                    console.error(error);
                    const message = error instanceof Error ? error.message : ETransactionErrorName.UNKNOWN_ERROR;
                    dispatchError({
                        name: ETransactionErrorName.REGISTER_CLAIMED_TRAIT_EVENT,
                        message,
                        code: -1,
                    });
                    return;
                }
            })
            .once(EContractTransactionStatus.DATA, ({ returnValues: { _wallet, _traitAddresses, _traitIds } }) => {
                if (_wallet.toLowerCase() !== walletAddress.toLowerCase()) {
                    return;
                }

                dispatch({
                    type: EActionTypes.DATA,
                    payload: {
                        tokenIdList: _traitIds,
                        traitAddresses: _traitAddresses,
                        error: null,
                        txId,
                        status: ETransactionStatus.SUCCESS,
                    },
                });
            })
            .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                dispatchError({ name: ETransactionErrorName.REGISTER_CLAIMED_TRAIT_EVENT, message, code } as TError)
            );
    };

    const registerFreezeAvatarEvent = () => {
        freezeContract.events
            .AvatarFrozen({}, (error: unknown, _) => {
                if (error) {
                    console.error(error);
                    const message = error instanceof Error ? error.message : ETransactionErrorName.UNKNOWN_ERROR;
                    dispatchError({ name: ETransactionErrorName.REGISTER_CLAIMED_TRAIT_EVENT, message, code: -1 });
                    return;
                }
            })
            .once(EContractTransactionStatus.DATA, ({ returnValues: { _, currentTokenId, owner } }) => {
                if (owner.toLowerCase() !== walletAddress.toLowerCase()) {
                    return;
                }

                dispatch({
                    type: EActionTypes.DATA,
                    payload: {
                        tokenIdList: [currentTokenId],
                        traitAddresses: null,
                        error: null,
                        txId,
                        status: ETransactionStatus.SUCCESS,
                    },
                });
            })
            .once(EContractTransactionStatus.ERROR, ({ code, message }: TError) =>
                dispatchError({ name: ETransactionErrorName.REGISTER_CLAIMED_TRAIT_EVENT, message, code } as TError)
            );
    };

    const prepareToCall = () =>
        dispatch({
            type: EActionTypes.CALLING_CONTRACT,
            payload: {
                tokenIdList: null,
                traitAddresses: null,
                error: null,
                txId: null,
                status: ETransactionStatus.WAITING_CONFIRMATION,
            },
        });

    const dispatchError = ({ name, message, code }: TError) =>
        dispatch({
            type: code === 4001 ? EActionTypes.RESET : EActionTypes.ERROR,
            payload: {
                tokenIdList: null,
                traitAddresses: null,
                error: { name, message, code },
                txId: null,
                status: code === 4001 ? ETransactionStatus.NO_ACTION : ETransactionStatus.ERROR,
            },
        });

    return {
        txId,
        error,
        status,
        tokenIdList,
        traitAddresses,
        createTraits,
        assembleAvatar,
        detachTrait,
        hasMintedTraits,
        getExternalTokenId,
        claimTrait,
        getClaimPrice,
        freezeAvatar,
    };
};

export default useAvatarContract;
